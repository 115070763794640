import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "preview", "currentImage", "remove"];

  preview() {
    const file = this.inputTarget.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewTarget.src = e.target.result;
        this.previewTarget.style.display = "block";
        if (this.hasCurrentImageTarget) {
          this.currentImageTarget.style.display = "none";
        }
        if (this.hasRemoveTarget) {
          this.removeTarget.style.display = "inline-block";
        } else {
          console.log("Remove target NOT found!");
        }
      };
      reader.readAsDataURL(file);
    }
  }

  remove(event) {
    if (confirm("Are you sure you want to delete this image?")) {
      const container = event.target.closest(".fileinput-container");

      const input = container.querySelector(
        '[data-image-preview-target="input"]',
      );
      const preview = container.querySelector(
        '[data-image-preview-target="preview"]',
      );
      const currentImage = container.querySelector(
        '[data-image-preview-target="currentImage"]',
      );
      const removeButton = container.querySelector(
        '[data-image-preview-target="remove"]',
      );

      if (input) input.value = "";
      if (preview) {
        preview.src = "";
        preview.style.display = "none";
      }
      if (currentImage) currentImage.style.display = "none";
      if (removeButton) removeButton.style.display = "none";

      const removeField = container.querySelector('[id^="remove_image_"]');
      if (removeField) {
        removeField.value = "true";
      }
    }
  }
}
